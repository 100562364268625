<template>
  <md-card class="note-card"
    v-click-outside="saveWhileQuittingFocus"
  >
    <div class="md-layout">
      <div class="nopadding md-layout-item md-layout md-size-90 md-medium-size-90">
        <custom-editor
          :description="noteText"
          @inputContent="noteTextChanged"
          @hasBeenFocused="() => $emit('hasBeenFocused')"
          :isFocused="isFocused"
          @save="onSave"
          @delete="onDelete"
          ref="customEditor"
          :borderless="true"
        ></custom-editor>
      </div>
      <div class="nopadding md-layout-item md-size-5 md-medium-size-5">
        <div class="action-container">
          <span v-if="readyToBeSaved()" @click="onSave">
            <md-icon class="icon-action icon-save">save</md-icon>
            <md-tooltip md-delay="300">Save [ctrl/cmd + s]</md-tooltip>
          </span>
        </div>
      </div>
      <div class="nopadding md-layout-item md-size-5 md-medium-size-5">
        <div class="action-container">
          <span @click="onDelete">
            <md-icon class="icon-action icon-delete">delete</md-icon>
            <md-tooltip md-delay="300">Delete [ctrl/cmd + d]</md-tooltip>
          </span>
        </div>
      </div>
      <div v-if="!hideInfo" class="nopadding md-layout-item md-layout md-size-100 md-medium-size-100">
        <span class="note-info" v-if="!hideCreationInfo">
          Created {{ createdDate }} by {{ note.creatorEmail }}
          <br/>
          <span v-if="!hideRelatedElementInfo">
            <router-link v-if="linkToRelatedElement" :to="linkToRelatedElement">
              {{ noteRelatedElementInfo }}
            </router-link>
            <span v-else>{{ noteRelatedElementInfo }}</span>
          </span>
        </span>
      </div>
    </div>
  </md-card>
</template>

<script>
import Vue from 'vue';
import CustomEditor from '@/components/Avalia/Editor/CustomEditor.vue';
import Swal from 'sweetalert2';
import moment from 'moment';

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    // eslint-disable-next-line no-param-reassign
    el.clickOutsideEvent = (event) => {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

const swalWithCustomButtons = Swal.mixin({
  customClass: {
    confirmButton: 'md-button md-success btn-fill',
    cancelButton: 'md-button md-danger btn-fill',
  },
  buttonsStyling: false,
});

export default {
  name: 'note',
  components: {
    CustomEditor,
  },
  props: {
    isFocused: {
      type: Boolean,
      default: false,
    },
    note: Object,
    isNewNote: {
      type: Boolean,
      default: false,
    },
    hideInfo: {
      type: Boolean,
      default: false,
    },
    hideCreationInfo: {
      type: Boolean,
      default: false,
    },
    hideRelatedElementInfo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      noteText: this.note.text ? this.note.text : '',
      isDeletionPopUpOpen: false,
      noteRelatedElementInfo: null,
      linkToRelatedElement: null,
    };
  },
  computed: {
    // Currently not used
    modifiedDate() {
      return moment(this.note.modifiedDate).format('DD.MM.YYYY');
    },
    createdDate() {
      // created date is now displayed in format "Created xx minutes ago"
      return moment(this.note.createdDate).fromNow();
    },
    isPopupClose() {
      return !this.isDeletionPopUpOpen;
    },
  },
  watch: {
    note(newNote) {
      if (newNote) {
        this.noteText = newNote.text;
      }
    },
  },
  created() {
    // On created
  },
  mounted() {
    this.linkToRelatedElement = this.computeLinkToRelatedElement();
    this.noteRelatedElementInfo = this.computeNoteRelatedElementInfo();
  },
  beforeDestroy() {
    this.onSave();
  },
  methods: {
    onSave() {
      // test if note change
      if (this.readyToBeSaved()) {
        this.saveNote();
        if (this.isNewNote) {
          this.resetNote();
        }
      }
    },
    saveNote() {
      const notePayload = this.createNotePayload();
      this.$emit('saveNote', notePayload);
    },
    saveWhileQuittingFocus() {
      if (!this.isDeletionPopUpOpen) {
        this.onSave();
      }
    },
    createNotePayload() {
      return {
        id: this.note.id,
        text: this.noteText,
      };
    },
    onDelete() {
      // If not a new not, ask confirmation
      if (!this.isNewNote) {
        this.showDeletionConfirmationPopUp();
      } else {
        this.deleteNote();
      }
    },
    showDeletionConfirmationPopUp() {
      this.isDeletionPopUpOpen = true;
      swalWithCustomButtons
        .fire({
          title: 'Are you sure?',
          text: 'Do you want to remove this note?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, remove it!',
          buttonsStyling: false,
          onClose: this.closeIsDeletionPopUpOpen,
        })
        .then((result) => {
          this.focusTextEditor();
          if (result.value) {
            this.deleteNote();
          }
        });
    },
    deleteNote() {
      this.resetNote();
      this.$emit('deleteNote', this.note);
    },
    resetNote() {
      this.noteText = '';
    },
    noteTextChanged(text) {
      this.noteText = text;
    },
    focusTextEditor() {
      this.$refs.customEditor.focusInput();
    },
    readyToBeSaved() {
      return this.noteText !== null && this.noteText !== '' && this.noteText !== this.note.text;
    },
    closeIsDeletionPopUpOpen() {
      // Didn't find other solution for this value change after loosing focus while clicking on cancel button
      setTimeout(() => this.isDeletionPopUpOpen = false, 200);
    },
    computeNoteRelatedElementInfo() {
      let noteRelatedElementInfo = '';

      if (this.note.metadata) {
        const MetadataRelatedElementType = this.note.metadata.find((metadata) => metadata.key === 'relatedElement');
        const MetadataRelatedElementName = this.note.metadata.find((metadata) => metadata.key === 'relatedElementName');
        let relatedElementType = MetadataRelatedElementType ? MetadataRelatedElementType.value : '';
        const relatedElementName = MetadataRelatedElementName ? MetadataRelatedElementName.value : '';

        relatedElementType = relatedElementType.charAt(0).toUpperCase() + relatedElementType.slice(1);
        noteRelatedElementInfo = `${relatedElementType} ${relatedElementName}`;
      }

      return noteRelatedElementInfo;
    },
    computeLinkToRelatedElement() {
      let relatedElementLink = null;

      if (this.note.metadata) {
        const metadataRelatedElementLink = this.note.metadata.find((metadata) => metadata.key === 'relatedElementLink');
        relatedElementLink = metadataRelatedElementLink ? metadataRelatedElementLink.value : null;
      }

      return relatedElementLink;
    },
  },
};
</script>

<style lang="scss" scoped>
.note-info {
  font-size: 0.8em;
  line-height: 15px;
  font-style: italic;
  color: $avalia-gray;
  a {
    color: $avalia-gray!important;
  }
}
.note-card {
  margin: 0;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}
.action-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 0rem;
}
</style>
