<template>
  <div
    class="editor-container"
    @keydown.ctrl.s.prevent.stop="() => $emit('save')"
    @keydown.meta.s.prevent.stop="() => $emit('save')"
    @keydown.ctrl.d.prevent.stop="() => $emit('delete')"
    @keydown.meta.d.prevent.stop="() => $emit('delete')"
  >
    <!-- will only been showed when focused -->
    <div class="editor">
      <editor-menu-bar
        :editor="editor"
        v-slot="{ commands, isActive }"
      >
        <div
          class="menubar"
          v-if="isMenuBarShowed"
          :key="'menubar-' + isMenuBarShowed"
        >
          <button
            class="icon-container menubar__button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <md-icon>format_bold</md-icon>
          </button>

          <button
            class="icon-container menubar__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <md-icon>format_italic</md-icon>
          </button>
          <button
            class="icon-container menubar__button custom-menu-button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
          >
            H1
          </button>
          <button
            class="icon-container menubar__button custom-menu-button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            H2
          </button>
          <button
            class="icon-container menubar__button custom-menu-button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            H3
          </button>
          <button
            class="icon-container menubar__button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <md-icon>format_list_bulleted</md-icon>
          </button>

          <button
            class="icon-container menubar__button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
          >
            <md-icon>format_list_numbered</md-icon>
          </button>
        </div>
      </editor-menu-bar>
      <editor-content
        ref="editorContent"
        class="editor__content"
        :class="{ 'editor-content-borders': !borderless }"
        :editor="editor"
      />
    </div>
  </div>
</template>

<script>
// import Fuse from 'fuse.js';
// import tippy from 'tippy.js';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Strike,
  Underline,
  History,
  Link,
  // Mention,
  Placeholder,
  // Image,
} from 'tiptap-extensions';

export default {
  name: 'custom-editor',
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: {
    description: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isFocused(focused) {
      if (focused) {
        this.focusInput();
      } else {
        this.unfocusInput();
      }
    },
    disabled(value) {
      this.editor.setOptions({
        editable: !value,
      });
    },
    description(value) {
      // Update the editor's content only if the prop was mutated from parent component
      if (value === this.editor.getHTML()) {
        return;
      }
      this.editor.setContent(value);
    },
  },
  computed: {
  },
  data() {
    return {
      isMenuBarShowed: false,
      editor: new Editor({
        editable: true,
        autoFocus: this.autoFocus,
        onFocus: this.focusedChange,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          // new Image(),
          new Placeholder({
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            emptyNodeText: '',
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
          }),
          // Add Mentions + Tippy (popup) methods and css here when needed according to
          // https://bitbucket.org/avaliasystems/avalia-activity-patterns-catalog/src/6a89d8b87072fe402d476183be17f6d1d09e71b1
          // /avalia-patterns/patterns-frontend/src/components/Avalia/Editor/CustomEditor.vue#lines-242
          // new Mention({ ...
        ],
        onUpdate: ({ getHTML }) => {
          const state = getHTML();
          this.$emit('inputContent', state);
        },
      }),
      linkUrl: null,
      linkMenuIsActive: false,
    };
  },
  created() {
    // On created
    if (this.description) {
      this.editor.setContent(this.description);
    }
  },
  mounted() {
    if (this.disabled) {
      this.editor.setOptions({
        editable: false,
      });
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command, url) {
      command({ href: url });
      this.hideLinkMenu();
    },
    focusedChange() {
      this.$emit('hasBeenFocused');
    },
    focusInput() {
      this.editor.focus();
      this.showMenuBar();
    },
    unfocusInput() {
      this.hidemenuBar();
    },
    showMenuBar() {
      this.isMenuBarShowed = true;
    },
    hidemenuBar() {
      this.isMenuBarShowed = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  font-weight: bold;
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  color: $avalia-black;
  padding: 0.2rem 0.5rem;
  margin-right: 0rem!important;
  border-radius: 3px;
  cursor: pointer;
  background-color: rgba($avalia-black, 0.1);

  &:hover {
    background-color: rgba($avalia-black, 0.15);
  }
}

.editor-container{
  width: 100%;
}
.custom-menu-button {
  color: $avalia-black;
  font-weight: 800;
}
.custom-menu-input {
  color: $avalia-black;
}
// for bubble button
.editor__content{
}
.editor-content-borders {
  border: 1px solid $avalia-gray;
}
.editor p{
  padding: 0.2rem;
}
// for placeholder
.editor p.is-editor-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
  font-style: italic;
}
// for links
a:hover {
  cursor: pointer;
}

// MAIN TIP TAP
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  &:focus {
    outline: none;
  }
}

*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: 'Inter';
  font-size: 18px;
  color: $avalia-black;
  line-height: 1.5;
}

body {
  margin: 0;
}

a {
  color: inherit;
}

h1,
h2,
h3,
p,
ul,
ol,
pre,
blockquote {
  margin: 0.1rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

h1,
h2,
h3 {
  line-height: 1.3;
}

.button {
  font-weight: bold;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: $avalia-black;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
  background-color: rgba($avalia-black, 0.1);

  &:hover {
    background-color: rgba($avalia-black, 0.15);
  }
}

.message {
  background-color: rgba($avalia-black, 0.05);
  color: rgba($avalia-black, 0.7);
  padding: 1rem;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  font-style: italic;
}

// Editor
.editor {
  position: relative;

  &__content {

    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    * {
      caret-color: currentColor;
    }

    pre {
      padding: 0.7rem 1rem;
      border-radius: 5px;
      background: $avalia-black;
      color: $avalia-gray;
      font-size: 0.8rem;
      overflow-x: auto;

      code {
        display: block;
      }
    }

    p code {
      padding: 0.2rem 0.4rem;
      border-radius: 5px;
      font-size: 0.8rem;
      font-weight: bold;
      background: rgba($avalia-black, 0.1);
      color: rgba($avalia-black, 0.8);
    }

    ul,
    ol {
      padding-left: 1rem;
    }

    li > p,
    li > ol,
    li > ul {
      margin: 0;
    }

    a {
      color: inherit;
    }

    blockquote {
      border-left: 3px solid rgba($avalia-black, 0.1);
      color: rgba($avalia-black, 0.8);
      padding-left: 0.8rem;
      font-style: italic;

      p {
        margin: 0;
      }
    }

    img {
      max-width: 100%;
      border-radius: 3px;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;

      td, th {
        min-width: 1em;
        border: 2px solid $avalia-gray;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;
        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: bold;
        text-align: left;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: "";
        left: 0; right: 0; top: 0; bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px; top: 0; bottom: 0;
        width: 4px;
        z-index: 20;
        background-color: #adf;
        pointer-events: none;
      }
    }

    .tableWrapper {
      margin: 1em 0;
      overflow-x: auto;
    }

    .resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }

  }
}

// MENU BAR
.menubar {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 1rem;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;

  &__button {
    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $avalia-black;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: rgba($avalia-black, 0.05);
    }

    &.is-active {
      background-color: rgba($avalia-black, 0.1);
    }
  }

  span#{&}__button {
    font-size: 13.3333px;
  }
}
</style>
